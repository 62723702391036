<template>
  <div class="recruitment" v-show="isOut">
    <div class="aft_banner">
      <img :src="$host + banner.litpic" alt="" />
      <!-- <img :src="$host + banner.litpicm" alt="" /> -->
    </div>
    <div class="aft_title p80">
      <div class="box">
        <p>{{ rczp_info.englist_name }}</p>
        <div>{{ rczp_info.typename }}</div>
      </div>
    </div>
    <div class="cooperationBox">
      <div class="box m160">
        {{ rczp_info.desc }}
      </div>
    </div>
    <div class="recruitmentBox m80 top1">
      <div class="aft_title p80">
        <div class="box">
          <p>{{ zpxx_cate.englist_name }}</p>
          <div>{{ zpxx_cate.typename }}</div>
        </div>
      </div>

      <div class="box m160">
        <div
          class="list"
          v-for="(item, index) in zpxx_list"
          :key="index"
          :class="{ active: item.lpk_status }"
        >
          <div class="boxTop flexBetween" @click="addClass(index)">
            <div class="boxLeft">
              <h5>{{ item.title }}</h5>
              <h6>{{ item.xz }}</h6>
            </div>
            <div class="boxRight">
              <div class="top flexStart">
                <div class="le">
                  <img src="../assets/images/address.png" alt="" />
                  {{ item.address }}
                </div>
                <div>
                  <img src="../assets/images/time.png" alt="" />
                  {{ item.times }}
                </div>
              </div>
              <div class="bot flexStart">
                <p v-for="(ite, ind) in item.tag" :key="ind">
                  {{ ite }}
                </p>
              </div>
            </div>

            <div class="button"><span></span></div>
          </div>

          <div class="boxBot" v-if="item.lpk_status">
            <div class="content1">
              <h5>岗位职责：</h5>
              <div>
                <p style="white-space: pre-wrap">{{ item.gwzz }}</p>
              </div>
            </div>

            <div class="content1">
              <h5>任职要求：</h5>
              <div>
                <p style="white-space: pre-wrap">{{ item.rzyq }}</p>
              </div>
            </div>
            <div class="content2 flexStart">
              <div class="left">
                <img src="../assets/images/media.png" alt="" />
              </div>
              <div class="right">
                <p>投递简历</p>
                <a @click="callEmail(item.email)">{{ item.email }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="aft_fengye">
        <div class="box flexCenter">
          <div class="btn prev" @click="PagePrev()">
            <a
              ><p>
                <svg
                  t="1655284142230"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2370"
                  data-spm-anchor-id="a313x.7781069.0.i6"
                  width="14"
                  height="14"
                >
                  <path
                    d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                    p-id="2371"
                    fill="#ffffff"
                  ></path>
                </svg></p
            ></a>
          </div>

          <div class="page_ flexCenter">
            <!-- <a href="" class="active"><span>01</span></a> -->

            <a
              v-for="(item, index) in last_page"
              :key="index"
              :class="{ active: PageIndex == index }"
              @click="changeHandler(index)"
            >
              <span v-if="index < 9">0{{ index + 1 }}</span>
              <span v-else>{{ index + 1 }}</span>
            </a>
          </div>
          <div class="btn next" @click="PageNext()">
            <a
              ><p>
                <svg
                  t="1655284142230"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2370"
                  data-spm-anchor-id="a313x.7781069.0.i6"
                  width="14"
                  height="14"
                >
                  <path
                    d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                    p-id="2371"
                    fill="#ffffff"
                  ></path>
                </svg></p
            ></a>
          </div>
        </div>
      </div>
    </div>
    <div class="strategy">
      <div class="aft_title p80 top2">
        <div class="box">
          <p>{{ rczl.englist_name }}</p>
          <div>{{ rczl.typename }}</div>
        </div>
      </div>
      <div class="box m160">
        <div class="content1">
          {{ rczl.desc }}
        </div>
        <div class="content2">
          <img :src="$host + rczl.img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recruitment",
  components: {
    // SidebarLpk,
  },
  data() {
    return {
      isOut: false,

      current: 0,
      banner: {},
      rczp_info: {},
      zpxx_cate: {},
      zpxx_list: [],

      pages: 1,
      last_page: 1,
      PageIndex: 0,

      rczl: {},
    };
  },
  created() {
    this.$http
      .get(
        "api.php/Webinfo/getjoin",
        {
          params: {
            page: this.pages,
          },
        },
        {
          emulateJSON: true,
        }
      )
      .then(
        function (res) {
          const recruitment = JSON.parse(res.data);
          this.banner = recruitment.banner;

          this.rczp_info = recruitment.rczp_info;
          this.zpxx_cate = recruitment.zpxx.cate;
          this.zpxx_list = recruitment.zpxx.list;

          this.last_page = recruitment.zpxx.last_page;
          this.rczl = recruitment.rczl;

          this.isOut = true;
          const idM = this.$route.query.top;
          this.$nextTick(() => {
            idM && this.$el.querySelector("." + idM).scrollIntoView();
          });
          // console.log(recruitment);
        },
        function (res) {
          console.log(res.status);
        }
      );
  },
  methods: {
    callEmail(callEmail) {
      window.location.href = "mailto:" + callEmail;
    },
    addClass: function (index) {
      this.zpxx_list[index].lpk_status = !this.zpxx_list[index].lpk_status;
      // this.current = index;
    },

    PagePrev() {
      if (this.pages > 1) {
        this.PageIndex--;
        this.pages--;
        this.$http
          .get(
            "api.php/Webinfo/getjoin",
            {
              params: {
                page: this.pages,
              },
            },
            {
              emulateJSON: true,
            }
          )
          .then(
            function (res) {
              const recruitment = JSON.parse(res.data);
              this.zpxx_list = recruitment.zpxx.list;
              window.scrollTo({
                top: 0,
                // behavior: "smooth",
              });
            },
            function (res) {
              console.log(res.status);
            }
          );
        window.scrollTo({
          top: 0,
        });
      }
    },
    PageNext() {
      if (this.pages < this.last_page) {
        this.PageIndex++;
        this.pages++;
        this.$http
          .get(
            "api.php/Webinfo/getjoin",
            {
              params: {
                page: this.pages,
              },
            },
            {
              emulateJSON: true,
            }
          )
          .then(
            function (res) {
              const recruitment = JSON.parse(res.data);
              this.zpxx_list = recruitment.zpxx.list;

              window.scrollTo({
                top: 0,
                // behavior: "smooth",
              });
            },
            function (res) {
              console.log(res.status);
            }
          );
        window.scrollTo({
          top: 0,
        });
      }
    },
    changeHandler(i) {
      this.PageIndex = i;

      this.pages = i + 1;

      this.$http
        .get(
          "api.php/Webinfo/getjoin",
          {
            params: {
              page: this.pages,
            },
          },
          {
            emulateJSON: true,
          }
        )
        .then(
          function (res) {
            const recruitment = JSON.parse(res.data);
            this.zpxx_list = recruitment.zpxx.list;
          },
          function (res) {
            alert(122);

            console.log(res.status);
          }
        );
      window.scrollTo({
        top: 0,
      });
    },
  },
  mounted() {
    this.$bus.on("productTops3", (value) => {
      this.$nextTick(() => {
        this.$el.querySelector("." + value).scrollIntoView();
      });
    });
  },
};
</script>

<style lang="less" scoped>
@gilroy_b: "gilroy_bold";
@gilroy_r: "gilroy_regular";
@albb_b: "albb_b";
@albb_r: "albb_r";
@albb_l: "albb_l";
@albb_m: "albb_m";
@color: #97c852;
.cooperationBox {
  .box {
    box-sizing: border-box;
    padding: 0 1.45rem;
    font-size: 0.24rem;
    font-family: @albb_b;
    line-height: 0.42rem;
    text-align: justify;
    margin-top: 0.64rem;
  }
}
.aft_fengye .box .page_ a span {
  border-bottom: 2px solid #f0f0f0;
}
.aft_fengye .box .page_ .active span {
  border-bottom: 2px solid #97c852;
}
.aft_banner {
  padding-bottom: 33.04%;
  position: relative;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
.recruitmentBox {
  background: #f0f0f0;
  padding-bottom: 1px;
  .aft_title {
    .box {
      p {
        color: #e5e5e5;
      }
    }
  }
  & > .box {
    margin-top: 0.4rem;
    .list {
      background: #fff;
      padding: 0 0.5rem;
      margin-bottom: 0.2rem;
      .boxTop {
        padding: 0.5rem 0;
        position: relative;
        cursor: pointer;
        .boxLeft {
          h5 {
            font-size: 0.36rem;
            font-family: @albb_m;
          }
          h6 {
            font-family: @gilroy_r;
            font-size: 0.4rem;
            color: @color;
            margin-top: 0.1rem;
          }
        }
        .boxRight {
          width: 70%;
          .top {
            div {
              font-size: 0.2rem;
              color: #666;
              font-family: @albb_m;
              padding-right: 0.1rem;
              img {
                vertical-align: middle;
                width: 0.26rem;
              }
            }
            .le {
              width: 40%;
            }
          }
          .bot {
            margin-top: 0.24rem;
            p {
              font-size: 0.18rem;
              font-family: @albb_m;
              color: #333;
              width: 1.38rem;
              border: 1px solid @color;
              line-height: 0.34rem;
              border-radius: 0.18rem;
              text-align: center;
              margin-right: 0.1rem;
            }
          }
        }
        .button {
          position: absolute;
          top: 50%;
          right: 0;
          span {
            display: block;
            width: 0.24rem;
            height: 2px;
            background: @color;
            margin-top: -1px;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: @color;
              transform: rotate(90deg);
              transition: 0.4s;
            }
          }
        }
      }
      .boxBot {
        border-top: 1px solid #ddd;
        padding-bottom: 0.64rem;
        // display: none;
        transition: 0.4s;
        .content1 {
          margin-top: 0.64rem;
          h5 {
            font-size: 0.2rem;
            font-family: @albb_b;
            margin-bottom: 5px;
          }
          div {
            p {
              font-size: 0.2rem;
              color: #666;
              line-height: 0.34rem;
            }
          }
        }
        .content2 {
          margin-top: 0.64rem;
          .left {
            img {
              width: 1.02rem;
            }
          }
          .right {
            margin-left: 0.45rem;
            p {
              font-size: 0.18rem;
              color: #666;
              margin: 5px 0 2px;
            }
            a {
              display: block;
              font-size: 0.26rem;
              font-family: @gilroy_b;
              color: @color;
            }
          }
        }
      }
    }
    .active {
      .boxTop {
        .button {
          span {
            &::after {
              transform: rotate(0deg);
            }
          }
        }
      }
      .boxBot {
        // display: block;
      }
    }
  }
}
.strategy {
  background: url(../assets/images/pro5.jpg) no-repeat 50% 50%;
  background-size: cover;
  .aft_title {
    .box {
      p {
        opacity: 0.1;
      }
      div {
        color: #fff;
      }
    }
  }
  & > .box {
    margin-top: 0.64rem;
    padding: 0 1.45rem;
    .content1 {
      font-size: 0.24rem;
      color: #fff;
      line-height: 0.42rem;
    }
    .content2 {
      text-align: center;
      padding: 1.2rem 0;
      img {
        max-width: 100%;
      }
    }
  }
}
@media screen and(max-width:1440px) {
  .recruitmentBox > .box .list .boxTop .boxRight .bot p {
    margin-bottom: 0.1rem;
  }
}
@media screen and(max-width:768px) {
  .cooperationBox .box {
    padding: 0;
  }
  .strategy > .box {
    padding: 0;
  }
  .recruitmentBox > .box .list {
    padding: 0 0.2rem;
  }
  .recruitmentBox > .box .list .boxTop .boxRight .top .le {
    width: 100%;
  }
  .recruitmentBox > .box .list .boxTop .boxLeft h5 {
    font-size: 0.3rem;
  }
  .recruitmentBox > .box .list .boxTop .boxLeft h6 {
    font-size: 0.34rem;
  }
  .recruitmentBox > .box .list .boxTop .boxRight .top div {
    line-height: 0.4rem;
  }
  .recruitmentBox > .box .list .boxTop .boxRight .bot p {
    margin-bottom: 0.1rem;
  }
  .recruitmentBox > .box .list .boxTop .boxRight {
    width: 90%;
    margin-top: 0.2rem;
  }
  .recruitmentBox > .box .list .boxBot .content2 .left img {
    width: 0.7rem;
  }
  .recruitmentBox > .box .list .boxTop .button {
    top: 17%;
  }
  .recruitmentBox .aft_title .box {
    margin-bottom: 0.5rem;
  }
  .cooperationBox .box {
    margin-top: 0.54rem;
  }
}
</style>
